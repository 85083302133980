import css from './PerformerListItem.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import MediaQuery from 'react-responsive'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'
import { CollectionEvent, CollectionFromIframe } from 'meta/pages/collection'
import { Routes } from 'meta/routes'
import { useAppDispatch } from 'store'
import { CheckoutActions } from 'store/pages/checkout'
import { Dates } from 'utils/dates'

import { ButtonWhiteBlack } from 'components/Actions/Action'
import Img from 'components/Media/Img'
import { useUrlCloudfront } from 'hooks/useEnv'

interface IProps {
  isPerformer?: boolean
}

const PerformerListItem: React.FC<CollectionEvent & CollectionFromIframe & IProps> = (props) => {
  const {
    coverImages,
    dateTimeEvent_tz,
    location,
    name,
    codeUrl,
    fromIframe,
    toggleContent,
    toDate_tz,
    fromDate_tz,
    numEvents,
  } = props

  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const openCheckout = () => {
    toggleContent && toggleContent()
    !isDisabled &&
      codeUrl &&
      fromIframe &&
      dispatch(CheckoutActions.init({ codeUrl, insideIFrame: true, fromCollectionIframe: true }))
  }

  //const isDisabled = Boolean(priceFrom) == false
  const isDisabled = false

  const isMonoDate = toDate_tz == fromDate_tz

  const eventPath = codeUrl ? Routes.Event.generatePath({ codeUrl }) : ''

  const urlCloudfront = useUrlCloudfront()

  const placeholderImgUrl = `${urlCloudfront}/images/carousel/placeholders/square_cover_ticketsms_placeholder.svg`

  const componentBody = (
    <div
      onClick={() => {
        codeUrl && !isDisabled && fromIframe && openCheckout()
      }}
      className={classNames(css.performerListItemContainer, { [css.disabled]: isDisabled })}
    >
      <div className={css.infoContainer}>
        <MediaQuery maxWidth={BreakpointsMaxWidth.mobile}>
          <div className={css.mobileImgContainer}>
            {coverImages.length > 0 && <img src={coverImages[0].url} className={css.image} />}
            {coverImages.length > 0 && <div className={classNames(css.bgBlackOverlay, css.bgBlurOverlay)} />}
            {coverImages.length > 0 && <div className={css.bgBlackOverlay} />}
            {coverImages.length == 0 && <img src={placeholderImgUrl} className={css.image} />}
            <div className={css.text}>
              {!isMonoDate && (
                <>
                  <div className={css.from}>
                    <span className={css.txt}>{t('collection:season_tickets_dates_from')}</span>
                    <span className={css.dateFrom}>
                      {Dates.format(fromDate_tz, t('dates:common:collection_season_mobile_from'))}
                    </span>
                  </div>
                  <div className={css.to}>
                    <span className={css.txt}>{t('collection:season_tickets_dates_to')}</span>
                    <span className={css.dateTo}>
                      {Dates.format(toDate_tz, t('dates:common:collection_season_mobile_to'))}
                    </span>
                  </div>
                </>
              )}
              {isMonoDate && (
                <>
                  <div className={css.from}>
                    <div className={css.dateFromMonoMobile}>
                      <span>
                        {String(Dates.format(dateTimeEvent_tz, t('dates:common:collection_day'))).toUpperCase()}
                      </span>
                      <span>
                        {String(Dates.format(dateTimeEvent_tz, t('dates:common:collection_month'))).toUpperCase()}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </MediaQuery>
        <MediaQuery minWidth={BreakpointsMaxWidth.mobile}>
          {coverImages.length > 0 && <img src={coverImages[0].url} className={css.image} />}
          {coverImages.length == 0 && <img src={placeholderImgUrl} className={css.image} />}
        </MediaQuery>

        <div className={classNames(css.details, css.performer)}>
          <h3 className={css.location}>{location?.city?.toUpperCase()}</h3>
          <div className={css.name}>{name}</div>
          <MediaQuery maxWidth={BreakpointsMaxWidth.tablet}>
            {!isDisabled && (
              <div className={css.priceAndDateContainer}>
                {!isMonoDate && fromDate_tz && toDate_tz && numEvents && (
                  <>
                    <span className={css.datesNo}>{t('collection:season_tickets_no_dates', { num: numEvents })}</span>
                    {t('collection:season_tickets_dates_from')}
                    {/* <span className={css.priceFromText}>{t('collection:price_from_mobile')}</span>
                    <span className={css.priceFrom}>{priceFrom && priceFrom?.formatted}</span> */}
                  </>
                )}

                {isMonoDate && dateTimeEvent_tz && (
                  <>
                    <span className={css.datesNo}>{t('collection:performer_event_unique_date')}</span>
                    {/* <span className={css.priceFromText}>{t('collection:price_from_mobile')}</span>
                    <span className={css.priceFrom}>{priceFrom && priceFrom?.formatted}</span> */}
                  </>
                )}
              </div>
            )}
          </MediaQuery>
          <MediaQuery minWidth={BreakpointsMaxWidth.tablet}>
            {!isDisabled && (
              <div className={css.priceAndDateContainer}>
                {!isMonoDate && fromDate_tz && toDate_tz && numEvents && (
                  <>
                    <span className={css.datesNo}>{t('collection:season_tickets_no_dates', { num: numEvents })}</span>
                    {/* {t('collection:season_tickets_dates_from')}
                    <span className={css.dateFrom}>
                      {Dates.format(fromDate_tz, t('dates:common:collection_season_from'))}
                    </span>
                    {t('collection:season_tickets_dates_to')}
                    <span className={css.dateTo}>
                      {Dates.format(toDate_tz, t('dates:common:collection_season_to'))}
                    </span>
                    {t('collection:season_tickets_dates_dot')}

                    <span className={css.priceFromText}>{t('collection:price_from')}</span>
                    <span className={css.priceFrom}>{priceFrom && priceFrom?.formatted}</span> */}
                  </>
                )}

                {isMonoDate && dateTimeEvent_tz && (
                  <>
                    <span className={css.datesNo}>{t('collection:performer_event_unique_date')}</span>
                    <span className={css.datesOn}>{t('collection:performer_event_unique_date_on')}</span>
                    <span className={css.dateFrom}>
                      {Dates.format(dateTimeEvent_tz, t('dates:common:collection_season_from'))}
                    </span>
                    {/* {t('collection:season_tickets_dates_dot')}
                    <span className={css.priceFromText}>{t('collection:price_from')}</span>
                    <span className={css.priceFrom}>{priceFrom && priceFrom?.formatted}</span> */}
                  </>
                )}
              </div>
            )}
          </MediaQuery>

          {isDisabled && (
            <div className={css.notAvailable}>
              {t('collection:tickets')}
              <span>{t('collection:not_available')}</span>
            </div>
          )}
        </div>
      </div>

      {codeUrl && !isDisabled && (
        <MediaQuery minWidth={BreakpointsMaxWidth.tablet}>
          <div className={css.action}>
            <ButtonWhiteBlack
              className={css.button}
              onClick={() => {
                codeUrl && !isDisabled && fromIframe && openCheckout()
              }}
            >
              <h5>{fromIframe ? t('collection:purchase') : t('collection:see_event')}</h5>{' '}
              <Img alt="" src="/icons/arrow_right.svg" />
            </ButtonWhiteBlack>
          </div>
        </MediaQuery>
      )}
    </div>
  )

  if (fromIframe || isDisabled) {
    return <>{componentBody}</>
  } else {
    return (
      <Link className={css.performerListItemContainerLink} to={eventPath}>
        {componentBody}
      </Link>
    )
  }
}

export default PerformerListItem
