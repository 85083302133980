import React from 'react'

import { IInfoBox, InfoBoxType } from 'meta/backendControlled/structure'

import { IPropsComponent } from 'components/BackendControlled/Component/index'
import B2c from 'components/BackendControlled/Component/InfoBox/B2c'

const InfoBox: React.FC<IPropsComponent<IInfoBox>> = (props) => {
  const { type, uuid } = props

  return (
    <div>
      {/*{type === InfoBoxType.b2b && <B2b uuid={uuid as string} />}*/}
      {type === InfoBoxType.b2c && <B2c uuid={uuid as string} />}
    </div>
  )
}

export default InfoBox
