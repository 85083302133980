export type AppEnv = {
  API_HOST: string
  TIKTOK_PIXELID: string
  URL_APP_ANDROID: string
  POS_BANK_REDIRECT_URL: string
  APP_ENV: string
  MAINTENANCE_MODE: string
  // URL_BUSINESS: string
  URL_BASE: string
  ENABLE_SALESFORCE_CHAT: string
  ENABLE_RECAPTCHA: string
  BLOG_API_KEY: string
  DISABLE_CACHE: string
  DISABLE_SATISPAY: boolean
  GOOGLE_MAPS_API_KEY: string
  BLOG_API_BASE: string
  FUN_URL: string
  GOOGLE_RECAPTCHA_V3_PUBLIC_KEY: string
  URL_APP_IOS: string
  PR_COOKIE_TIME: string
  FACEBOOK_PIXELID: string
  CSRF_TOKEN: string
  BACKEND_API_V1_BASE: string
  GOOGLE_CLIENT_ID: string
  OTP_CONFIG_KEY: string
  PROXY_API_BASE: string
  STRIPE_API_KEY: string
  URL_MY: string
  VENUE_MAPS_TYPE: string
  OTP_TOKEN: string
  FACEBOOK_DOMAIN_VERIFICATION: string
  DATADOG_CLIENT_TOKEN: string
  GOOGLE_ANALYTICS_ID: string
  APPLE_CLIENT_ID: string
  FACEBOOK_APPID: string
  URL_CLOUDFRONT: string
  ACTIVATE_LOGGER: string
  S2S_TOKEN: string
}

export enum EnvType {
  development = 'development',
  staging = 'staging',
  production = 'production',
}
