import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { MapType } from 'types/cartographer'

import { RouteParams } from 'meta/routes'

import Loader from 'components/Layout/Loader'
import Map from 'components/Pages/Cartographer/Map'
import { useCartographerQueryParams } from 'hooks/useQueryParams'

const Cartographer: React.FC = () => {
  const { eventId, typeTicketCollectionId } = useParams<RouteParams['Cartographer']>()
  const queryParams = useCartographerQueryParams()

  const [isCartographerLibraryLoaded, setCartographerLibraryLoaded] = useState<boolean>(false)

  const {
    codeUrl,
    headers: headersParam,
    lang,
    mapId,
    mapType,
    mode = 'shop',
    platform,
    preview,
    promoterId,
    seatIds,
  } = queryParams
  // const langHeader = headers['accept-language']

  const headers: Record<string, string> = headersParam ? JSON.parse(decodeURIComponent(headersParam)) : {}

  useEffect(() => {
    const timer = setInterval(() => {
      // @ts-expect-error window might not have property
      if (window && window.cartographerReady && !isCartographerLibraryLoaded) {
        setCartographerLibraryLoaded(true)
      }
    }, 500)

    return () => clearInterval(timer)
  })

  return (
    <>
      {isCartographerLibraryLoaded ? (
        <Map
          codeUrl={codeUrl ? String(codeUrl) : undefined}
          headers={headers}
          eventId={parseInt(String(eventId))}
          typeTicketCollectionId={typeTicketCollectionId ? parseInt(String(typeTicketCollectionId)) : undefined}
          // language={langQuery ?? langHeader ?? 'it'}
          lang={lang}
          mapId={String(mapId)}
          mapType={String(mapType) as MapType}
          mode={String(mode)}
          platform={String(platform)}
          preview={preview === 'true'}
          promoterId={promoterId ? Number(promoterId) : undefined}
          seatIds={seatIds}
        />
      ) : (
        <Loader />
      )}
    </>
  )
}

export default Cartographer
