import css from './EventListItem.module.scss'
import React from 'react'
import { useTranslation } from 'react-i18next'
import MediaQuery from 'react-responsive'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { BreakpointsMaxWidth } from 'meta/layout/breakpoints'
import { CollectionEvent, CollectionFromIframe } from 'meta/pages/collection'
import { Routes } from 'meta/routes'
import { useAppDispatch } from 'store'
import { CheckoutActions } from 'store/pages/checkout'
import { Dates } from 'utils/dates'

import { ButtonWhiteBlack } from 'components/Actions/Action'
import Img from 'components/Media/Img'

const EventListItem: React.FC<CollectionEvent & CollectionFromIframe> = (props) => {
  const { coverImages, dateEvent_tz, name, codeUrl, fromIframe, toggleContent } = props

  const { t } = useTranslation()

  const dispatch = useAppDispatch()

  const openCheckout = () => {
    toggleContent && toggleContent()
    !isDisabled &&
      codeUrl &&
      fromIframe &&
      dispatch(CheckoutActions.init({ codeUrl, insideIFrame: true, fromCollectionIframe: true }))
  }

  const isDisabled = false

  const eventPath = codeUrl ? Routes.Event.generatePath({ codeUrl }) : ''

  const componentBody = (
    <div
      onClick={() => {
        codeUrl && !isDisabled && fromIframe && openCheckout()
      }}
      className={classNames(css.eventListItemContainer, { [css.disabled]: isDisabled })}
    >
      <div className={css.infoContainer}>
        <img src={coverImages[0]?.url} className={css.image} />

        <div className={css.details}>
          <h3 className={css.datetime}>{Dates.format(dateEvent_tz, t('dates:common:collection'))}</h3>
          <div className={css.name}>{name}</div>
          {/* {!isDisabled && (
            <div className={css.priceFrom}>
              {t('collection:price_from')}
              <span>{priceFrom && priceFrom?.formatted}</span>
            </div>
          )} */}
          {isDisabled && (
            <div className={css.notAvailable}>
              {t('collection:tickets')}
              <span>{t('collection:not_available')}</span>
            </div>
          )}
        </div>
      </div>

      {codeUrl && !isDisabled && (
        <MediaQuery minWidth={BreakpointsMaxWidth.tablet}>
          <div className={css.action}>
            <ButtonWhiteBlack
              className={css.button}
              onClick={() => {
                codeUrl && !isDisabled && fromIframe && openCheckout()
              }}
            >
              <h5>{fromIframe ? t('collection:purchase') : t('collection:see_event')}</h5>{' '}
              <Img alt="" src="/icons/arrow_right.svg" />
            </ButtonWhiteBlack>
          </div>
        </MediaQuery>
      )}
    </div>
  )

  if (fromIframe || isDisabled) {
    return <>{componentBody}</>
  } else {
    return (
      <Link className={css.eventListItemContainerLink} to={eventPath}>
        {componentBody}
      </Link>
    )
  }
}

export default EventListItem
