import { createSlice } from '@reduxjs/toolkit'

import i18n from 'i18n'
import { LanguageCode } from 'meta/app/translate'
import { initialState } from 'store/pages/performer/state'

import { getPerformerKey } from '../_utils'
import { fetchData } from '../actions/fetchData'
import { fetchEvents } from '../actions/fetchEvents'
import { fetchMetadata } from '../actions/fetchMetadata'
import { fetchSeasons } from '../actions/fetchSeasons'

export const PerformerSlice = createSlice({
  name: 'performer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // fetch
    builder.addCase(fetchData.fulfilled, (state, action) => {
      const { id, details } = action.payload

      const language = (i18n.language?.substring(0, 2) as LanguageCode) ?? LanguageCode.it
      const path = getPerformerKey({ id, language })

      state[path] = { ...state[path], details }
    })
    builder.addCase(fetchEvents.fulfilled, (state, action) => {
      const { id, events } = action.payload

      const language = (i18n.language?.substring(0, 2) as LanguageCode) ?? LanguageCode.it
      const path = getPerformerKey({ id, language })

      state[path] = { ...state[path], events }
    })
    builder.addCase(fetchSeasons.fulfilled, (state, action) => {
      const { id, seasons } = action.payload

      const language = (i18n.language?.substring(0, 2) as LanguageCode) ?? LanguageCode.it
      const path = getPerformerKey({ id, language })

      state[path] = { ...state[path], seasons }
    })
    builder.addCase(fetchMetadata.fulfilled, (state, action) => {
      const { id, metadata } = action.payload

      const language = (i18n.language?.substring(0, 2) as LanguageCode) ?? LanguageCode.it
      const path = getPerformerKey({ id, language })

      state[path] = { ...state[path], metadata }
    })
  },
})
