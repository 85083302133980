// @ts-nocheck
import { AppEnv } from 'meta/app'

export const defaultEnv: AppEnv = {
  ACTIVATE_LOGGER: undefined,
  API_HOST: undefined,
  APPLE_CLIENT_ID: undefined,
  APP_ENV: undefined,
  BACKEND_API_V1_BASE: undefined,
  BLOG_API_BASE: undefined,
  BLOG_API_KEY: undefined,
  CSRF_TOKEN: undefined,
  DATADOG_CLIENT_TOKEN: undefined,
  DISABLE_CACHE: undefined,
  DISABLE_SATISPAY: false,
  ENABLE_RECAPTCHA: undefined,
  ENABLE_SALESFORCE_CHAT: undefined,
  FACEBOOK_APPID: undefined,
  FACEBOOK_DOMAIN_VERIFICATION: undefined,
  FACEBOOK_PIXELID: undefined,
  FUN_URL: undefined,
  GOOGLE_ANALYTICS_ID: undefined,
  GOOGLE_CLIENT_ID: undefined,
  GOOGLE_MAPS_API_KEY: undefined,
  GOOGLE_RECAPTCHA_V3_PUBLIC_KEY: undefined,
  MAINTENANCE_MODE: undefined,
  OTP_CONFIG_KEY: undefined,
  OTP_TOKEN: undefined,
  POS_BANK_REDIRECT_URL: undefined,
  PROXY_API_BASE: undefined,
  PR_COOKIE_TIME: undefined,
  S2S_TOKEN: undefined,
  STRIPE_API_KEY: undefined,
  TIKTOK_PIXELID: undefined,
  URL_APP_ANDROID: undefined,
  URL_APP_IOS: undefined,
  URL_BASE: undefined,
  // URL_BUSINESS: undefined,
  URL_CLOUDFRONT: undefined,
  URL_MY: undefined,
  VENUE_MAPS_TYPE: undefined,
}
