import css from './PDFBox.module.scss'
import React from 'react'
import classNames from 'classnames'

import { PdfBoxColor } from 'meta/pages/governance'

import Img from 'components/Media/Img'
import { useUrlCloudfront } from 'hooks/useEnv'

interface IProps {
  title: string
  link: string
  filename: string
  color?: PdfBoxColor
}

const PDFBox: React.FC<IProps> = (props) => {
  const { title, link, filename, color } = props

  const urlCloudfront = useUrlCloudfront()

  const fileUrl = `${urlCloudfront}${link}`

  const handleDownload = async () => {
    try {
      const response = await fetch(fileUrl)

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()

      link.parentNode?.removeChild(link)
      window.URL.revokeObjectURL(url)
    } catch (error) {
      // handle error
    }
  }

  return (
    <div className={classNames(css.pdfbox_container, { [css.grey]: color == PdfBoxColor.grey })}>
      <div className={css.info}>
        <Img alt="" src="/icons/governance-icon-pdf.svg" />
        <div className={css.title}>{title}</div>
      </div>
      <div className={css.actions}>
        <a href={fileUrl} target="_blank" rel="noreferrer">
          <Img alt="" src="/icons/pdfbox-view.svg" />
        </a>
        <a onClick={handleDownload}>
          <Img alt="" src="/icons/pdfbox-download.svg" />
        </a>
      </div>
    </div>
  )
}

export default PDFBox
