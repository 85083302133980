import css from './Payments.module.scss'
import React from 'react'

import { Breakpoints } from 'meta/layout/breakpoints'

import { GridColumn } from 'components/Layout/Grid'
import Img from 'components/Media/Img'

const Payments: React.FC = () => {
  return (
    <GridColumn
      gridColumnStart={{ [Breakpoints.mobile]: 1, [Breakpoints.tablet]: 1, [Breakpoints.laptop]: 1 }}
      gridColumnEnd={{ [Breakpoints.mobile]: 17, [Breakpoints.tablet]: 8, [Breakpoints.laptop]: 7 }}
      className={css.footerPayments}
    >
      <Img src="/payment_methods/footer/mastercard.svg" />
      <Img src="/payment_methods/footer/visa.svg" />
      <Img src="/payment_methods/footer/paypal.svg" />
      <Img src="/payment_methods/footer/scalapay.svg" />
      {/* <Img src="/payment_methods/footer/18app.svg" /> */}
      <Img className={css.fixedHeight} src="/payment_methods/cartaMeritoCultura.svg" />
      <Img className={css.fixedHeight} src="/payment_methods/footer/satispay.svg" />
    </GridColumn>
  )
}

export default Payments
