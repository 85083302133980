import css from './Social.module.scss'
import React from 'react'

import { Breakpoints } from 'meta/layout/breakpoints'

import { GridColumn } from 'components/Layout/Grid'
import Img from 'components/Media/Img'
import { useBlogApiBase } from 'hooks/useEnv'

const Social: React.FC = () => {
  return (
    <GridColumn
      gridColumnStart={{ [Breakpoints.mobile]: 1, [Breakpoints.tablet]: 10, [Breakpoints.laptop]: 13 }}
      gridColumnEnd={{ [Breakpoints.mobile]: 17, [Breakpoints.tablet]: 17, [Breakpoints.laptop]: 17 }}
      gridRowStart={{ [Breakpoints.mobile]: 10, [Breakpoints.tablet]: 6, [Breakpoints.laptop]: 6 }}
      className={css.footerSocial}
    >
      <a title={'TicketMAG Logo'} href={useBlogApiBase()} target="_blank" rel="noreferrer">
        <Img
          alt="TicketMAG"
          className={css.footerSocial__ticketMag}
          src="/social/2024/social icons_2024_TicketSms Mag.svg"
        />
      </a>
      <a title={'Facebook'} href="https://www.facebook.com/ticketsms" target="_blank" rel="noreferrer">
        <Img className={css.fb} src="/social/2024/social icons_2024_fb.svg" />
      </a>
      <a title={'Instagram'} href="https://www.instagram.com/ticketsms" target="_blank" rel="noreferrer">
        <Img className={css.instagram} src="/social/2024/social icons_2024_instagram.svg" />
      </a>
      <a title={'TikTok'} href="https://www.tiktok.com/@ticketsms?_t=8Yxl1CR4otR&_r=1" target="_blank" rel="noreferrer">
        <Img className={css.tiktok} src="/social/2023/social icons_2023_tiktok.svg" />
      </a>
      <a title={'Linkedin'} href="https://www.linkedin.com/company/ticketsms" target="_blank" rel="noreferrer">
        <Img className={css.linkedin} src="/social/2024/social icons_2024_linkedin.svg" />
      </a>
      <a
        title={'Treedom'}
        href="https://www.treedom.net/it/organization/ticketsms/event/ticketsms-forest"
        target="_blank"
        rel="noreferrer"
      >
        <Img className={css.treedom} src="/social/2024/social icons_2024_treedom.svg" />
      </a>
    </GridColumn>
  )
}

export default Social
