import css from './MenuMobile.module.scss'
import cssModal from 'components/Layout/Modal/Modal.module.scss'
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { Routes } from 'meta/routes'
import { useAppDispatch } from 'store'
import { AuthActions } from 'store/auth'
import { LoginActions } from 'store/pages/login'

import { LinkTarget } from 'components/Actions/Action'
import ModalSelector from 'components/Layout/Footer/LanguageSelector/ModalSelector'
import { useIsInsideProfileRoute } from 'components/Layout/Header/hooks/useIsInsideProfileRoute'
import { useAuthToken, useUserData } from 'hooks/useAuth'
// import ModalSelector from '../../Footer/LanguageSelector/ModalSelector'
import { useBlogApiBase } from 'hooks/useEnv'

import { MenuMobileButton, MenuMobileLink } from './Voice'

type Props = {
  searchOpened: boolean
  showLogin(): void
}

const MenuMobile: React.FC<Props> = (props) => {
  const { searchOpened, showLogin } = props

  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  // const urlBusiness = useUrlBusiness()
  const urlBlog = useBlogApiBase()
  const userData = useUserData()
  const insideProfile = useIsInsideProfileRoute()

  const [open, setOpen] = useState<boolean>(false)
  const [showLangModal, setShowLangModal] = useState<boolean>(false)

  const loggedIn = Boolean(useAuthToken())

  useEffect(() => {
    if (open) document.body.classList.add(cssModal.modal_active)
    else document.body.classList.remove(cssModal.modal_active)
  }, [open])

  useEffect(() => {
    if (searchOpened) setOpen(false)
  }, [searchOpened])

  return (
    <>
      <div
        className={classNames(css.btnMenuToggle, { [css.open]: open })}
        onClick={() => setOpen((prevState) => !prevState)}
      >
        <span />
        <span />
        <span />
      </div>

      <div className={classNames(css.menuMobile, { [css.open]: open })}>
        <MenuMobileButton
          icon={'/header/tickets.svg'}
          label={t('header:tickets')}
          onClick={() => {
            if (!loggedIn) {
              dispatch(LoginActions.show({ pathname: Routes.Wallet.generatePath() }))
            } else {
              navigate(Routes.Wallet.generatePath())
            }

            setOpen(false)
          }}
        />
        {loggedIn && (
          <MenuMobileLink
            href={Routes.Profile.generatePath()}
            icon={'/header/profile.svg'}
            label={
              userData && userData?.firstName && userData?.lastName
                ? `${userData!.firstName} ${userData!.lastName[0]}.`
                : t('header:profile')
            }
          />
        )}
        {!insideProfile && !loggedIn && (
          <MenuMobileButton
            icon={'/header/profile.svg'}
            label={t('header:login')}
            onClick={() => {
              setOpen(false)
              showLogin()
            }}
          />
        )}
        {/*<MenuMobileLink*/}
        {/*  href={urlBusiness}*/}
        {/*  icon={'/header/add_event.svg'}*/}
        {/*  label={t('header:create_event_mobile')}*/}
        {/*  target={LinkTarget.blank}*/}
        {/*/>*/}
        <MenuMobileLink href={'/support/form'} icon={'/header/help.svg'} label={t('header:help')} />
        {/* <MenuMobileLink
                    href={appDownload}
                    icon={'/header/download_app.svg'}
                    labelKey={'header:app_download_mobile'}
                    target={LinkTarget.blank}
                    t={t}
                /> */}
        <MenuMobileLink href={urlBlog} icon={'/header/blog.svg'} label={t('header:blog')} target={LinkTarget.blank} />
        <MenuMobileButton
          icon={'/header/lang.svg'}
          label={t('header:language')}
          onClick={() => {
            setOpen(false)
            setShowLangModal(true)
          }}
        />
        {loggedIn && (
          <MenuMobileButton
            icon={'/header/logout.svg'}
            label={t('header:logout')}
            onClick={() => {
              setOpen(false)
              dispatch(AuthActions.logout())
            }}
          />
        )}
      </div>

      {showLangModal && ReactDOM.createPortal(<ModalSelector onClose={() => setShowLangModal(false)} />, document.body)}
    </>
  )
}

export default MenuMobile
